<template>
  <v-app>
    <Header></Header>
    <v-card
        elevation="10"
        outlined
        class="card"

    >
      <v-list-item-title class="content">
        <h1><b>DISCLOSURE POLICY</b></h1>
      </v-list-item-title>
      <br><br>
      <div class="content">
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        (hereinafter collectively referred to as
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        ”, “We”, “Us” or “Our”), utilizes certain
        trusted third parties in the course of business including through our website
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        (“Site”). This
        policy will be treated as part of our Terms of Use and is automatically incorporated therein.<br><br>

        The terms “you”, “user”, “your” refer to all individuals and other persons including but not limited to any
        individual, company, organization, legal entity or otherwise their respective employees, agents or
        representatives who access or use our services. By continuing to visit or use our services, you are agreeing to
        the use of third parties mentioned in this policy below for the purposes we describe in this policy.<br><br>

        <span class="card-internal-heading">Third Parties:</span><br>
        A third party to us and you in the context of our privacy notice includes but is not limited to our business
        partners, suppliers, sub-contractors, advertisers & advertising networks, analytics & search engine providers,
        sales and marketing partners, payment providers (hereinafter collectively referred to as “third parties”). The
        following third parties may use tools and technologies which includes cookies, web beacons, pixels, local
        storage, sessions storage etc.<br><br>

        These companies may collect information about other apps on your various devices used by you, the websites you
        visit, location information, and other information. In addition, we may share information with partners for
        Service improvements, research and analysis, to help us provide you more meaningful experiences and content and
        to help us communicate with you about any offers from us.<br><br>

        <span class="card-internal-heading">Analytics Partners</span><br>
        Some of our partners help us better understand how a user or visitor to our site interacts with it, navigates
        through the various features, which features are the most engaging etc. This is required for us to improve our
        services. The analytics providers with whom we work include, but are not limited to, the companies listed below.
        Some of these companies provide users choices about how they collect and use information. For more information,
        please review their privacy policies<br><br>

        Google Analytics<br><br>

        <span class="card-internal-heading">Social Networking</span><br>
        We may implement some social media buttons and certain other widgets that allow our users to interact and share
        content. Your interaction with a widget generally allows the third party to collect some information about you,
        for example your IP Address. In certain cases, the third party might be able to identify you through its widget
        even if you do not use it but visit another site that also uses the widget. Mentioned below are the partners
        whose widgets are allowed on our site.<br><br>

        Google India Private Limited<br><br>

        <span class="card-internal-heading">Service Providers</span><br>
        We utilize the services of the following third parties to help us run our business. For example, a chat service
        provider to enable live chat feature for the end user, a payment service provider to processing payment and
        marketing service providers to enable our outreach programs.<br><br>

        <span class="card-internal-heading">Payment Service Provider</span><br>

        Razorpay Software Private Limited<br><br>

        <span class="card-internal-heading">Hosting and Datacenter</span><br>

        Amazon Web Services<br><br>

        <span class="card-internal-heading">Marketing Service Providers</span><br>

        Google India Private Limited<br><br>

        <b>Please note that the third parties we work with might be subject to changes from time to time hence you are
        requested to keep revisiting our privacy notice time to time.</b><br>
      </div>
    </v-card>
    <br>
    <v-divider></v-divider>
    <br>
    <Footer></Footer>
    <div class="text-center">
    </div>
  </v-app>

</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
  name: "disclosure",
  components: {Footer, Header}
}
</script>

<style scoped>

.content {
  text-align: center;
}

.card {
  max-width: 65vw;
  position: relative;
  left: 20%;
  padding: 10px;
}

.card-internal-heading {
  font-size: larger;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {

  .card {
    max-width: 95vw;
    position: relative;
    left: 2%;
    padding: 10px;
  }
}

</style>